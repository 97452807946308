<template>
    <div>
        <breadcrumbs
            :items="breadItem"
        ></breadcrumbs>
        <div class="header-page">
            <div class="header-font">
                {{headerForm}}
            </div>
            <div>
                <v-btn class="mr-2" color="primary" outlined @click="cancel">Cancel</v-btn>
                <v-btn color="primary" @click="submit">Save</v-btn>
            </div>
        </div>
        <div class="d-flex d-flex-space-between">
            <v-card class="card-padding student-info mr-4">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">Student Information</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.firstName"
                                        :rules="inputRules"
                                        label="First name"
                                        :error-messages="errors.firstName"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.lastName"
                                        :rules="inputRules"
                                        label="Last name"
                                        :error-messages="errors.lastName"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.nickname"
                                        :rules="inputRules"
                                        label="Nickname"
                                        :error-messages="errors.nickname"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="datePickerMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateDisplay"
                                            label="Date of Birth"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.dateOfBirth"
                                            @input="datePickerMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.email"
                                        :rules="inputRules"
                                        label="Email"
                                        :error-messages="errors.email"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.phone"
                                        :rules="inputRules"
                                        :error-messages="errors.phone"
                                        placeholder="000-000-0000"
                                        label="Phone"
                                        v-mask="'###-###-####'"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
            </v-card>
            <v-card class="card-padding ep-info">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">EP Information</div>
                    </div>
                </div>
                <v-form
                    ref="formEp"
                    v-model="validEp">
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-select
                                v-model="form.branchId"
                                :items="branchOptions"
                                label="Branch"
                                item-text="key"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="form.point"
                                :rules="inputRules"
                                label="Student Point"
                                :error-messages="errors.point"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-select
                                v-model="form.level"
                                :items="levelOptions"
                                label="Level"
                                item-text="key"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="form.studentId"
                                :rules="inputRules"
                                label="Student ID"
                                :error-messages="errors.studentId"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '../components/Breadcrumbs'

export default {
    name: 'StudentForm',
    components: {
        Breadcrumbs
    },

    data () {
        return {
            valid: false,
            validEp: false,
            errors: {},
            form: {
                firstName: '',
                lastName: '',
                nickname: '',
                dateOfBirth: '',
                email: '',
                phone: '',
                studentId: '',
                point: '',
                level: '',
                branchId: ''
            },
            levelOptions: [],
            branchOptions: [],
            inputRules: [
                v => !!v || 'This field is required'
            ],
            selectRules: [
                (v) => v.length > 0 || 'This field is required'
            ],
            headerForm: '',
            datePickerMenu: false,
            dateFormat: '',
            mode: '',
            studentSystemId: '',
            breadItem: [
                {
                    text: 'Students',
                    disabled: false,
                    link: true,
                    exact: true,
                    to: {
                        path: '/students'
                    }
                },
                {
                    text: '',
                    disabled: true
                }
            ]
        }
    },

    computed: {
        dateDisplay () {
            return this.formatDate(this.form.dateOfBirth)
        }
    },
    methods: {
        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchOptions = res.data.data
            }
        },

        async getLevelOptions () {
            const res = await this.axios.get('students-level-select')
            if (res && res.data && res.data.status === 'ok') {
                this.levelOptions = res.data.data
            }
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        async getStudentInfo () {
            this.studentSystemId = this.$route.params.id
            const res = await this.axios.get(`students/${this.studentSystemId}`)
            if (res && res.data && res.data.status === 'ok') {
                this.form = res.data.data
            }
        },

        async checkAction () {
            if (this.$route.path.includes('create')) {
                this.mode = 'create'
            } else {
                this.mode = 'edit'
                await this.getStudentInfo()
            }
            this.headerForm = `${this.mode.charAt(0).toUpperCase() + this.mode.slice(1)} Student`
            this.breadItem[1].text = this.headerForm
        },

        cancel () {
            this.$router.push({ path: '/students' })
        },

        async submit () {
            if (!this.$refs.form.validate() | !this.$refs.formEp.validate()) {
                return
            }

            if (this.mode === 'create') {
                await this.createStudent()
            } else {
                await this.editStudent()
            }
        },

        async createStudent () {
            const res = await this.axios.post('students', this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.$router.push({ path: '/students' })
                this.$notify({
                    title: 'Congratulations',
                    text: `Create student  ${this.form.firstName} ${this.form.lastName} success`,
                    type: 'success'
                })
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
                this.$notify({
                    title: 'Error',
                    text: 'Cannot create some field is invalid',
                    type: 'error'
                })
            }
        },

        async editStudent () {
            const res = await this.axios.put(`students/${this.studentSystemId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.$router.push({ path: '/students' })
                this.$notify({
                    title: 'Congratulations',
                    text: `Edit student  ${this.form.firstName} ${this.form.lastName} success`,
                    type: 'success'
                })
            }
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getLevelOptions()
        await this.checkAction()
    }
}
</script>

<style lang="scss" scoped>
.student-info {
    width: 55%;
}

.ep-info {
    width: 40%;
    height: fit-content;
}
</style>
